import {clone} from "../../common/js/tool";
import {volunStatusOptions, volunteerModifyTypeOptions} from "../../common/hr/volunteer";

/* 控件字段名 */
const label = {
    key: 'label',
    label: '控件名称',
    component: 'a-input',
    props: {
        placeholder: '请输入控件名称',
    },
    rules: [{ required: true, message: '请输入控件名称', trigger: 'blur' }]
}

/* 未输入占位 */
const placeholder = {
    key: 'placeholder',
    label: '未输入占位',
    component: 'a-input',
    props: {
        placeholder: '请输入用户未填写时显示内容',
    },
}

/* input 文本后缀 */
const suffix = {
    key: 'suffix',
    label: '文本后缀',
    component: 'a-input',
    props: {
        placeholder: '文本后缀',
    },
}

/* 选项列表 */
const options = {
    key: 'options',
    label: '选项列表',
    slot: "controlOptions",
    rules: [{ required: true, message: '请设置选项列表', trigger: 'change' }]
}

/* 默认时间 */
const defaultTime = {
    key: "defaultValue",
    label: "默认值",
    slot: "defaultTime",
};

/* 默认值选择 */
const defaultValueSelect = {
    key: "defaultValue",
    label: "默认值",
    slot: "defaultValueSelect",
}

/* 默认值输入 */
const defaultValueInput = {
    key: 'defaultValue',
    label: '默认值',
    component: 'a-input',
    props: {
        placeholder: '请输入',
    },
}

/* 禁用 */
const disabled = {
    key: 'disabled',
    label: '禁用',
    component: 'a-checkbox',
}

/* 多选 */
const multiple = {
    key: 'multiple',
    label: '多选',
    component: 'a-checkbox',
}

/* 数量上限 */
const limit = {
    key: 'limit',
    label: '数量上限',
    component: 'a-input-number',
    props: {
        min: 1,
    },
    style: {
        width: "100%"
    }
}

/* 字段名 */
const field = {
    key: 'key',
    label: '字段名',
    component: 'a-input',
    props: {
        placeholder: '请输入字段名',
    },
    rules: [{ required: true, message: '请输入字段名', trigger: 'blur' }]
}

/* 数据联动 */
const dataLink = {
    key: "link",
    label: "数据联动",
    slot: "dataLink",
}

/**
 * 控件编辑项配置
 * {
 *     [key: string 控件类型 (component值)]: Array<control> | {[key: string]: { [key: string | 'default']: Array<control> }}
 * }
 *  示例1：表示input component控件的配置表单项包含placeholder和suffix两个编辑项
 *  input: [placeholder, suffix]
 *
 *  示例2：表示input component控件prop type为textarea的配置表单项只包含placeholder一个配置，其他默认则包含placeholder和suffix两个
 *  如果有多个 prop 配置则会进行合并
 *  input: {
 *      base: [], // 基本配置
 *      type: {
 *          default: [placeholder, suffix],
 *          textarea: [placeholder],
 *      },
 *  },
 */

export const controlFormConfig = {
    input: {
        base: [defaultValueInput, disabled],
        type: {
            default: [placeholder, suffix],
            textarea: [placeholder],
        },
    },
    select: {
        base: [placeholder, disabled],
        mode: {
            selector: [options, { ...defaultValueSelect, mode: "default" }],
            date: [{ ...defaultTime, mode: "date" }],
            time: [{ ...defaultTime, mode: "time" }],
        }
    },
    radio: [options],
    checkbox: [options],
    upload: {
        base: [
            {
                ...limit,
                props: {
                    ...limit.props,
                    placeholder: "最大文件上传数"
                }
            }
        ],
        excludeKeys: ["show_in_list"]
    },
    "date-time": [
        placeholder,
        { ...defaultTime, mode: "date-time" },
        disabled
    ],
    "half-date": [
        placeholder,
        { ...defaultTime, mode: "date", label: "默认日期", key: "default_date" },
        {
            key: 'default_time',
            label: '默认时间段',
            component: 'a-select',
            props: {
                placeholder: "默认时间段",
                options: [
                    { key: -1, label: "当前时间" },
                    { key: 0, label: "上午" },
                    { key: 1, label: "下午" },
                ],
                allowClear: true
            },
        },
        { ...dataLink }
    ],
    book: {
        base: [placeholder, multiple, disabled],
        type: {
            user: [{
                key: 'cc',
                label: '抄送成员',
                component: 'title-checkbox',
                props: {
                    title: "审批通过后抄送选择的成员"
                }
            }]
        }
    },
    "multiple-select": [placeholder, options, { ...defaultValueSelect, mode: "multiple" }],
    tk: [placeholder],
    asset: [placeholder],
    meal: [
        {
            key: 'prev',
            label: '提前天数',
            component: 'a-input-number',
            props: {
                placeholder: '最多提前几天可预订',
                min: 0,
            },
            style: {
                width: "100%"
            },
            rules: [{ required: true, message: '请输入提前天数', trigger: 'blur' }]
        },
        {
            key: 'max',
            label: '可预订天数',
            component: 'a-input-number',
            props: {
                placeholder: '最多可预订几天',
            },
            style: {
                width: "100%"
            },
            rules: [{ required: true, message: '请输入最多可预订几天', trigger: 'blur' }]
        },
        {
            key: 'defaultValue',
            label: "默认用餐时间",
            slot: "mealTime"
        }
    ],
    subscribe: [
        {
            key: 'goods_type',
            label: '物品类型',
            component: 'a-select',
            props: {
                placeholder: '请选择物品类型',
                options: [
                    { label: '申购物品', value: 1},
                    { label: '领用物品', value: 2},
                    { label: '借用物品', value: 3},
                    { label: '礼品', value: 4},
                ],
            },
            rules: [{ required: true, message: '请选择物品类型', trigger: 'change' }]
        },
        {

            key: 'manual',
            label: '手动添加',
            component: 'title-checkbox',
            props: {
                title: "可手动添加物品"
            }
        },
        {
            key: 'in_tk',
            label: '限制堂口',
            component: 'title-checkbox',
            props: {
                title: "只能选择已选择堂口下的物品"
            }
        },
    ],
    car: [
        {
            ...limit,
            props: {
                ...limit.props,
                placeholder: "最多可添加多少辆车"
            }
        },
        {
            key: 'open',
            label: '联系人不必填',
            component: 'title-checkbox',
            props: {
                title: "车辆联系人和联系电话非必填"
            }
        },
    ],
    visitors: [
        {
            key: 'title',
            label: '添加按钮名称',
            component: 'a-input',
            props: {
                placeholder: '请输入添加按钮添加按钮',
            },
            rules: [{ required: true, message: '请输入添加按钮添加按钮', trigger: 'blur' }]
        },
        {
            key: 'chargeTip',
            label: '负责人提示',
            component: 'a-textarea',
            props: {
                placeholder: '是否为负责人选框提示信息',
                rows: 4
            },
        }
    ],
    address_book: [
        {
            key: 'type',
            label: '通讯录类型',
            component: 'a-radio-group',
            props: {
                options: [
                    { label:'成员', value: 'user' },
                    { label:'部门', value: 'department' },
                ],
            },
            rules: [{ required: true, message: '请选择通讯录类型', trigger: 'change' }]
        },
        {
            key: 'range',
            label: '指定范围',
            slot: "addressBookRange",
            // rules: [{ required: true, message: '请选择通讯录指定范围', trigger: 'change' }]
        },
        multiple
    ],
    volunteer_modify: {
        base: [{
            key: 'type',
            label: '变更类型',
            component: 'a-select',
            props: {
                options: [...volunteerModifyTypeOptions],
            },
            rules: [{ required: true, message: '请选择变更类型', trigger: 'change' }]
        }],
        type: {
            status: [
                {
                    key: 'limit',
                    label: '条件限制',
                    component: 'a-radio-group',
                    props: {
                        options: [
                            { label:'不限制', value: 0 },
                            { label:'符合面试条件', value: 1 },
                            { label:'符合转正条件', value: 2 },
                        ],
                    },
                    rules: [{ required: true, message: '请选择志工条件限制', trigger: 'change' }]
                },
                {
                    key: 'register_status',
                    label: '注册状态',
                    component: 'a-select',
                    props: {
                        placeholder: "指定注册状态筛选志工",
                        options: [...volunStatusOptions],
                        allowClear: true
                    },
                },
                {
                    key: 'status',
                    label: '申请状态',
                    component: 'a-select',
                    props: {
                        placeholder: "指定注册状态筛选志工",
                        options: [...volunStatusOptions, { label: '休眠前状态', value: 'previous'}],
                    },
                    rules: [{ required: true, message: '请选择志工申请状态', trigger: 'change' }]
                }
            ]
        }
    },
    attendance: {
        base: [
            {
                key: 'holiday_types',
                label: '可选请假类型',
                slot: "holidayType",
            },
            {
                key: 'min_count',
                label: '最小请假时长',
                slot: "minDayCount",
            },
        ],
        excludeKeys: ["rules", "rely", "show_in_list"]
    },
    reception: {
        excludeKeys: ["key", "reception"]
    }
}

const commonItems = [
    {
        key: 'rules',
        label: "输入校验",
        slot: "valueValidate"
    },
    {
        key: 'rely',
        label: "控件依赖",
        slot: "controlRely"
    },
    {
        key: 'show_in_list',
        label: '列表展示',
        component: 'title-checkbox',
        props: {
            title: "此控件的内容将在审批列表中简要展示"
        }
    },
    {
        key: 'noGap',
        label: '隐藏间距',
        component: 'title-checkbox',
        props: {
            title: "勾选后此控件会紧挨上一个控件"
        }
    }
]

export const getControlFormItems = function (control, dev) {
    const component = control.component;
    const config = controlFormConfig[component];
    let res = [
        {
            ...label,
            props: {
                ...label.props,
                disabled: control.component == 'attendance'
            }
        }
    ];
    if(dev) {
        res.push(field);
    }
    // 某些特殊控件 不需要的编辑项
    const exclude = [];
    if(config) {
        if(Array.isArray(config)) {
            res.push(...config);
        } else {
            for(let key in config) {
                switch (key) {
                    case "base":
                        res.push(...config.base);
                        break;
                    case "excludeKeys":
                        exclude.push(...config.excludeKeys);
                        break;
                    default: {
                        const value = control[key];
                        if (value) {
                            const childConfig = config[key][value] || config[key].default || [];
                            childConfig.forEach(c => {
                                if (res.findIndex(item => item.key == c.key) === -1) {
                                    res.push(c);
                                }
                            });
                        }
                        break;
                    }
                }
            }
        }
    }
    res.push(...commonItems);
    if(dev) {
        res.push(
            {
                key: 'unDelete',
                label: '不可删除',
                component: 'title-checkbox',
                props: {
                    title: "此控件不可删除"
                }
            },
            {
                key: 'reception',
                label: '接待控件',
                component: 'title-checkbox',
                props: {
                    title: "当应用作为接待需求时此控件展示在接待需求表单中"
                }
            }
        );
    }
    // 移除某些特殊控件 不需要的编辑项
    if(exclude.length > 0) {
        exclude.forEach(k => {
           const i = res.findIndex(c => c.key == k);
           if(i >= 0) {
               res.splice(i, 1);
           }
        });
    }
    return clone(res);
}
