<template>
  <div class="data-link">
    <div class="flex-box align-center" style="height: 40px" v-if="show">
      <a-select v-model="key" placeholder="请选择联动的表单控件" style="margin-right: 8px">
        <a-select-option :value="c.key" v-for="c in linkControls" :key="c.key"
                         :title="c.label">{{c.label}}
        </a-select-option>
      </a-select>
      <a-select
        v-model="value"
        placeholder="请选择联动值"
        :options="halfDateOptions"
        @change="dispatchChange"
        v-show="key"
        v-if="component === 'half-date'">
      </a-select>
      <a-icon type="close-circle" theme="filled" class="clear-rely" @click="clearLink"/>
    </div>
    <a-button @click="setLink" v-else>设置</a-button>
  </div>
</template>

<script>

    import {isEmpty} from "../../common/js/tool";0

    export default {
        name: "data-link",
        model: {
            event: "change",
            prop: "link"
        },
        props: {
            link: Object,
            component: String,
            linkControls: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
                key: undefined,
                value: undefined,
                show: false,
                halfDateOptions: [
                    { key: -1, label: "当前时间" },
                    { key: 0, label: "上午" },
                    { key: 1, label: "下午" },
                ]
            }
        },
        watch: {
            link() {
                this.setKeyValue();
            }
        },
        created() {
            this.setKeyValue();
        },
        methods: {
            setKeyValue() {
                const link = this.link;
                if(link) {
                    this.key = link.key;
                    this.value = link.value;
                    this.show = true;
                } else {
                    this.key = undefined;
                    this.value = undefined;
                    this.show = false;
                }
            },
            clearLink() {
                this.key = undefined;
                this.value = undefined;
                this.show = false;
                this.dispatchChange();
            },
            setLink() {
                this.show = true;
            },
            dispatchChange() {
                if(!isEmpty(this.key) && !isEmpty(this.value)) {
                    this.$emit("change", { key: this.key, value: this.value });
                } else {
                    this.$emit("change", undefined);
                }
            }
        }
    }
</script>

<style scoped>

</style>
