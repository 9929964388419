<template>
    <div class="custom-form">
        <div class="form-conf">
            <div class="preview-area">
                <drag-zone :list="form" class="form-preview">
                    <template v-slot="{item: control, index}">
                        <div class="form-item"
                             :class="{select: item && item.key == control.key, error: control.error, edit: editable && edit}"
                             @click="selectFormItem(control)">
                            <template v-if="control.component == 'attendance'">
                                <div class="form-ctx" :class="{'no-gap': c.noGap}"
                                     v-for="(c, i) in attendanceFormItems[control.type]" :key="i">
                                    <div class="form-label">{{c.label}}</div>
                                    <div class="form-input">
                                        <div class="input-place">{{c.placeholder}}</div>
                                    </div>
                                </div>
                            </template>
                            <div class="form-ctx" :class="{'form-vertical': isVertical(control), 'no-gap': control.noGap}" v-else>
                                <div class="form-label">{{control.label}}<span v-if="isMust(control.rules)"> *</span>
                                </div>
                                <div class="form-opt-show"
                                     v-if="control.options && (control.component == 'radio' || control.component == 'checkbox')">
                                    <div class="opt-show-item" :class="{radio: control.component == 'radio'}"
                                         v-for="(opt, i) in control.options" :key="i">
                                        <div class="opt-show-icon"></div>
                                        <div class="opt-show-title">{{opt}}</div>
                                    </div>
                                </div>
                                <div class="form-item-upload icon-affix"
                                     v-else-if="control.component == 'upload'"></div>
                                <div class="form-input"
                                     :class="{textarea: control.component == 'input' && control.type == 'textarea', disabled: control.disabled}"
                                     v-else>
                                    <div class="input-place">{{control.placeholder}}</div>
                                    <a-icon class="icon-arrow-right" type="right" v-if="isShowArrow(control)"/>
                                </div>
                            </div>
                            <a-icon class="form-item-remove" type="close" @click.stop="removeItem(control, index)" v-if="!control.unDelete"/>
                        </div>
                    </template>
                </drag-zone>
                <a-space class="add-item" @click.stop="showItems" v-show="editable && edit">
                    <a-icon type="plus"/>
                    <span>添加控件</span>
                </a-space>
                <form-controls ref="controls" class="form-temp" @select="itemAdd"></form-controls>
            </div>
            <div class="item-setting" v-if="!editable">
                <h3 class="setting-title">表单暂不可自定义配置</h3>
            </div>
            <div class="item-setting" v-else>
                <h3 class="setting-title">控件设置</h3>
                <div class="setting-form-area" v-if="item">
                    <form-area
                        ref="setForm"
                        layout="horizontal"
                        hide-btn
                        direct
                        :label-col="{span: 4}"
                        :wrapper-col="{span: 12}"
                        :items="items"
                        :entity="item">
                        <template v-slot:defaultTime="{form: config, control}">
                            <default-time v-model="config[control.key]" :mode="control.mode"/>
                        </template>
                        <template v-slot:defaultValueSelect="{form: config, control}">
                            <a-select
                                v-model="config[control.key]"
                                placeholder="请选择"
                                :mode="control.mode"
                                :options="getSelectOptions(item.options)"/>
                        </template>
                        <template v-slot:mealTime="{form: config, control}">
                            <meal-time-range v-model="config[control.key]" :prev="config.prev" :max="config.max" />
                        </template>
                        <template v-slot:holidayType="{form: config, control}">
                            <holiday-type v-model="config[control.key]" :options="holidayOptions" />
                        </template>
                        <template v-slot:controlOptions="{form: config, control}">
                            <control-options v-model="config[control.key]" />
                        </template>
                        <template v-slot:addressBookRange="{form: config, control}">
                            <select-book multiple v-model="config[control.key]" :type="config.type"></select-book>
                        </template>
                        <template v-slot:valueValidate="{form: config, control}">
                            <value-validate v-model="config[control.key]" @change="handleRulesChange"></value-validate>
                        </template>
                        <template v-slot:controlRely="{form: config, control}">
                            <control-rely v-model="config[control.key]" :rely-controls="itemRelyControls"></control-rely>
                        </template>
                        <template v-slot:minDayCount="{form: config, control}">
                            <min-day-count v-model="config[control.key]"></min-day-count>
                        </template>
                        <template v-slot:dataLink="{form: config, control}">
                            <data-link v-model="config[control.key]" :link-controls="itemLinkControls" :component="item.component"></data-link>
                        </template>
                    </form-area>
                </div>
            </div>
        </div>
        <a-space class="form-btn" v-show="edit" v-if="!hideBtn">
            <a-button @click="cancel">取 消</a-button>
            <a-button type="primary" @click="saveForm">保 存</a-button>
        </a-space>
    </div>
</template>

<script>
    import dragZone from '../drag-zone'
    import formControls from './form-controls';
    import {relyComponents} from "./formItems";
    import {clone, randomString, isArray} from "../../common/js/tool";
    import Scroller from "../../common/js/scroll";
    import {getStaffTypeName} from "../../common/hr/book";
    import defaultTime from "./default-time";
    import mealTimeRange from "./meal-time-range";
    import holidayType from "./holiday-type";
    import controlOptions from "./control-options";
    import valueValidate from "./value-validate";
    import controlRely from "./control-rely";
    import minDayCount from "./min-day-count";
    import dataLink from "./data-link";
    import { volunStatusOptions, volunteerModifyTypeOptions } from "../../common/hr/volunteer";
    import { getControlFormItems } from "./controlForm";

    export default {
        name: "custom-form",
        components: {
            dragZone,
            formControls,
            defaultTime,
            holidayType,
            mealTimeRange,
            controlOptions,
            valueValidate,
            controlRely,
            minDayCount,
            dataLink,
        },
        props: {
            list: Array,
            dev: Boolean, // 调试模式 可以修改字段名
            editable: {
                type: Boolean,
                default: true
            }, // 是否可编辑
            edit: Boolean,
            hideBtn: Boolean
        },
        data() {
            return {
                item: null, //当前选择的表单
                form: [], //表单列表
                setRules: {
                    label: [{required: true, message: '请输入控件名称', trigger: 'blur'}],
                    goods_type: [{required: true, message: '请选择物品类型', trigger: 'change'}],
                    relyValue: [
                        {required: true, message: '请输入依赖值', trigger: 'blur'},
                        {required: true, message: '请选择依赖值', trigger: 'change'}
                    ]
                },
                attendanceFormItems: {
                    leave: [
                        {label: '请假类型', placeholder: '请选择'},
                        {label: '开始时间', placeholder: '请选择'},
                        {label: '结束时间', placeholder: '请选择', noGap: true},
                        {label: '请假时长', placeholder: '请输入', noGap: true},
                    ],
                    monk_leave: [
                        {label: '请假类型', placeholder: '请选择'},
                    ],
                    trip: [
                        {label: '开始时间', placeholder: '请选择'},
                        {label: '结束时间', placeholder: '请选择', noGap: true},
                    ],
                    overtime: [
                        {label: '开始时间', placeholder: '请选择'},
                        {label: '结束时间', placeholder: '请选择', noGap: true},
                        {label: '加班时长', placeholder: '请输入', noGap: true},
                    ],
                    out: [
                        {label: '开始时间', placeholder: '请选择'},
                        {label: '结束时间', placeholder: '请选择', noGap: true},
                        {label: '外出时长', placeholder: '请输入', noGap: true},
                    ],
                },
                volunStatusOptions,
                volunteerModifyTypeOptions,
                holidayList: null
            }
        },
        computed: {
            //可被依赖的表单控件数组
            relyControls() {
                const form = this.form;
                const res = [];
                form.forEach(c => {
                    if(relyComponents.indexOf(c.component) >= 0) {
                        const control = clone(c);
                        if(control.component == 'select') {
                            control.options = control.options ? control.options.map(o => typeof o === 'object' ? o : Object({key: o, title: o})) : [];
                        }
                        res.push(control);
                    } else if(c.component == "attendance" && c.type == 'leave') {
                        const con = {
                            component: 'select',
                            mode: 'selector',
                            label: '请假类型',
                            placeholder: '请选择',
                            key: 'leave_type',
                            rangeKey: "name",
                            options: this.holidayList,
                        }
                        res.push(con);
                    }
                });
                return res;
            },
            itemRelyControls() {
                const item = this.item;
                return item ? this.relyControls.filter(c => c.key != item.key) : [...this.relyControls];
            },
            itemLinkControls() {
                const item = this.item;
                return item ? this.form.filter(c => c.key != item.key && c.component === item.component) : [];
            },
            items() {
                const item = this.item;
                let res = [];
                if(item) {
                    res = getControlFormItems(item, this.dev);
                }
                return res;
            },
            // 假勤组件能选择的假期选项列表
            holidayOptions() {
                let res = [];
                const item = this.item;
                if(item && item.component == 'attendance') {
                    const holidayList = this.holidayList;
                    // 普通请假
                    if(item.type == 'leave') {
                        res = holidayList.filter(item => item.h_type == 1);
                    } else if(item.type == "monk_leave") {
                        // 僧职告假
                        res = holidayList.filter(item => item.h_type == 2);
                    }
                }
                return res;
            }
        },
        watch: {
            list() {
                this.setForm();
            },
            item(val) {
                if(val && val.component == 'attendance' && val.type == 'leave') {
                    this.getHolidayList();
                }
            }
        },
        created() {
            this.setForm();
        },
        mounted() {
            let dom = this.$el.querySelector(".form-preview");
            dom && (this.scroller = new Scroller(dom));
        },
        methods: {
            getHolidayList() {
                if(this.holidayList) {
                    return Promise.resolve(this.holidayList);
                } else {
                    return this.$axios('/admin/holiday?pageSize=1000').then(res => {
                        const list = res.data.map(item => {
                            return {
                                key: item.id,
                                title: `${item.name}-${getStaffTypeName(item.type)}`,
                                name: item.name,
                                h_type: item.h_type
                            }
                        });
                        this.holidayList = list;
                        return list;
                    });
                }
            },
            getSelectOptions(options) {
                options = options || [];
                return options.map(opt => typeof opt !== "object" ? Object({key: opt, title: opt}) : opt);
            },
            isShowArrow(c) {
                return ["select", "book", "tk", "asset", "volunteer_modify", "address_book"].indexOf(c.component) >= 0;
            },
            isVertical(c) {
                return c.component == 'upload' || (c.component == 'input' && c.type == 'textarea');
            },
            isMust(rules) {
                let flag = false;
                if (rules && rules.length > 0) {
                    flag = rules.findIndex(r => r.rule && r.rule.required) >= 0
                }
                return flag
            },
            setForm() {
                let list = this.list;
                //不传list 传入不是数组 或者传入数组长度为0 视为一个新的表单
                if (list && isArray(list) && list.length > 0) {
                    this.form = clone(this.list);
                    if(list.findIndex(c => c.component == "attendance" && c.type == 'leave') >= 0) {
                        this.getHolidayList();
                    }
                } else {
                    this.form = [];
                }
            },
            saveForm(confirm) {
                return new Promise((resolve, reject) => {
                    this.checkCurrent(() => {
                        let form = this.form;
                        let flag = true;
                        for (let i = 0, l = form.length; i < l; i++) {
                            let item = form[i];
                            if (item.error) {
                                flag = false;
                                this.item = item;
                                this.$nextTick(() => {
                                    this.checkCurrent();
                                    this.scrollControl(i);
                                });
                                break;
                            }
                        }
                        if (flag) {
                            this.item = null;
                            form.forEach(item => {
                                if (item.rely && item.relyValue) {
                                    item.rely = {
                                        key: item.rely.key,
                                        value: item.relyValue
                                    };
                                    delete item.relyValue;
                                }
                                delete item.error;
                            });
                            resolve(JSON.stringify(form));
                            this.$emit("save", JSON.stringify(form), confirm);
                        } else {
                            reject("请正确配置表单项");
                            this.$message.warning("请正确配置表单项");
                        }
                    })
                })
            },
            cancel() {
                this.item = null;
                this.setForm();
                this.$emit("update:edit", false);
            },
            handleRulesChange(rules) {
                this.$set(this.item, "must", this.getMust(rules));
            },
            getMust(rules) {
                let must = false;
                if (rules && rules.length > 0) {
                    must = rules.findIndex(r => r.rule.required) >= 0;
                }
                return must;
            },
            checkCurrent(fn) {
                if (this.$refs.setForm) {
                    this.$refs.setForm.handleConfirm().then(() => {
                        this.$set(this.item, 'error', false);
                    }).catch(() => {
                        this.$set(this.item, 'error', true);
                    }).finally(() =>  typeof fn === "function" && fn());
                } else {
                    fn(true);
                }
            },
            selectFormItem(item, fn) {
                if (!this.edit) return;
                //检查当前表单项配置
                this.checkCurrent(() => {
                    //点击当前选中的表单项取消选中状态
                    if (this.item && this.item.key == item.key) {
                        this.item = null;
                    } else {
                        this.item = item;
                        this.$nextTick(() => {
                            this.checkCurrent();
                            typeof fn === "function" && fn();
                        });
                    }
                });
            },
            removeItem(control, index) {
                this.form.splice(index, 1);
                //删除被选中的 清空选中
                if (this.item && this.item.key == control.key) {
                    this.item = null;
                }
            },
            showItems() {
                this.$refs.controls.showControls();
            },
            itemAdd(c) {
                let type = c.type;
                let form = this.form;
                if (type === 'attendance' && form.findIndex(f => f.component === 'attendance') >= 0) {
                    this.$message.warning('一个模板仅支持添加一种假勤组件');
                    return;
                } else if (type === 'special' && form.findIndex(f => f.key === c.config.key) >= 0) {
                    this.$message.warning('一个模板仅支持添加一个同一类型特殊组件');
                    return;
                }
                let conf = clone(c.config);
                if (!conf.key) {
                    let str = randomString(8);
                    let key = `${conf.component}_${str}`;
                    if (form.some(f => f.key == key)) {
                        str = randomString(8);
                        key = `${conf.component}_${str}`;
                    }
                    conf.key = key;
                }
                conf.label = c.title;
                this.form.push(conf);
                this.selectFormItem(conf, () => {
                    this.scrollControl(this.form.length - 1);
                });
            },
            scrollControl(index) {
                let item = document.querySelectorAll(".form-item")[index].parentNode;
                this.scroller.scrollTo(item);
            },
        }
    }
</script>

<style scoped lang="less">
    .custom-form {
        display: flex;
        flex-direction: column;
        padding: 10px;
        height: 100%;
    }

    .form-conf {
        flex: 1;
        display: flex;
        min-height: 0;
    }

    .preview-area {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 377px;
        height: 100%;
        border: var(--border);
    }

    .form-preview {
        flex: 1;
        background-color: @background-color-base;
        overflow: auto;
    }

    .add-item {
        flex-shrink: 0;
        justify-content: center;
        height: 48px;
        border-top: var(--border);
        cursor: pointer;
        font-size: 14px;
        color: @primary-color;

        &:hover {
            background-color: @background-color-light;
        }

        .icon-plus {
            margin-right: 6px;
            width: 14px;
            height: 14px;
            line-height: 14px;
        }
    }

    .form-temp {
        position: absolute;
        bottom: 0;
        left: -12px;
        z-index: 3;
    }

    .form-item {
        position: relative;

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px dashed @primary-color;
        }

        &.edit {
            cursor: move;

            &:hover {
                &::after {
                    display: block;
                }

                .form-item-remove {
                    display: block;
                }
            }
        }

        &.error {
            &::after {
                display: block;
                border: 1px solid @error-color;
            }
        }

        &.select {
            &::after {
                display: block;
                border: 1px solid @primary-color;
            }

            .form-item-remove {
                display: block;
            }
        }

        .base-tip {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            z-index: 1;
        }

        .el-icon-info {
            font-size: 16px;
            line-height: 1;
            color: @primary-color;
        }
    }

    .form-ctx {
        display: flex;
        position: relative;
        margin-top: 12px;
        padding: 7px 10px;
        background-color: @component-background;
        border-bottom: var(--border);

        &.no-gap {
            margin-top: 0;
        }

        &.form-vertical {
            flex-direction: column;

            .form-label {
                width: auto;
            }

            .form-input {
                flex: none;
                padding: 0;
                text-align: left;
            }
        }
    }

    .form-input {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 0 5px 0 10px;
        height: 32px;
        text-align: right;
        color: @text-color;

        .icon-arrow-right {
            font-size: 16px;
            color: @text-color-secondary;
        }

        &.textarea {
            align-items: normal;
            padding-top: 10px;
            height: 60px;
        }

        &.disabled {
            background-color: @background-color-base;
        }
    }

    .input-place {
        flex: 1;
        color: @text-color-secondary;
    }

    .form-label {
        width: 80px;
        line-height: 32px;

        span {
            color: @error-color;
        }
    }

    .form-opt-show {
        padding-top: 6px;
    }

    .opt-show-item {
        display: flex;
        align-items: center;
        margin-top: 6px;

        &.radio .opt-show-icon {
            border-radius: 100%;
        }

        .opt-show-icon {
            width: 16px;
            height: 16px;
            border: var(--border);
        }

        .opt-show-title {
            margin-left: 10px;
            font-size: 14px;
        }
    }

    .form-item-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border: var(--border);
        border-radius: 4px;
        font-size: 24px;
    }

    .form-item-remove {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px;
        width: 18px;
        height: 18px;
        background-color: @primary-color;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        color: @component-background;
        cursor: pointer;
        z-index: 2;
    }

    .item-setting {
        flex: 1;
        margin-left: 40px;
        padding-right: 16px;
        overflow: auto;
    }

    .setting-form-area {
        margin-top: 20px;
    }

    .form-btn {
        margin-top: 16px;
        padding-top: 16px;
        border-top: var(--border);
        justify-content: center;
    }

    .hint-title {
        margin: 20px 0;
    }
</style>
